// Bootstrap overrides

//
// Color system
//

$white: #fff;
$gray-100: #f0f3f5;
$gray-200: #c2cfd6;
$gray-300: #a4b7c1;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #29363d;
$gray-900: #151b1e;
$black: #000 !default;

$disabled-text: $gray-600;

$blue: #197fa4;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545;
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #31844f !default;
$teal: #20c997 !default;
$cyan: #63c2de;

$colors: (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
);

$theme-colors: (
    primary: $blue,
    secondary: $gray-300,
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800,

    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,

    gray-100: $gray-100,
    gray-200: $gray-200,
    gray-300: $gray-300,
    gray-400: $gray-400,
    gray-500: $gray-500,
    gray-600: $gray-600,
    gray-700: $gray-700,
    gray-800: $gray-800,
    gray-900: $gray-900,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.
$body-bg: #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-size-base: 0.875rem;

// Breadcrumbs
$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

// Cards
$card-border-color: $gray-500;
$card-cap-bg: $gray-100;

// Dropdowns
$dropdown-padding-y: 0;
$dropdown-border-color: $gray-500;
$dropdown-divider-bg: $gray-100;

// Buttons
$btn-secondary-border: $gray-300;

// Progress bars
$progress-bg: $gray-100;

// Tables
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-500;
$input-group-addon-border-color: $gray-500;

/* Importing Bootstrap SCSS file. */
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/bootstrap/scss/utilities/api';
